import React, { useEffect } from "react";
import Logo from "../../assets/imgs/corp_logo.png";
import Gumshoe from "gumshoejs";
import DiscordButtonImage from '../../assets/imgs/discord_icon.png';
import PlayButtonImage from '../../assets/imgs/play_icon.png';
import FacebookButtonImage from '../../assets/imgs/facebook_icon.png';
import "./Header.css";

export default function Header() {

  useEffect(() => {
    new Gumshoe('#header-navigator a');
  }, []);

  return (
    <header className="header">
      <div className="logo">
        <img alt="Knock Studio" src={Logo}/>
      </div>
      <nav className="navigator" id="header-navigator">
        <ul>
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#heros">Heros</a>
          </li>
          <li>
            <a href="#download">Download</a>
          </li>
          <li>
            <a href="#contact_us">Contact Us</a>
          </li>
        </ul>
      </nav>
      <div className="shortcut">
        <ul>
          <li>
            <a href="https://play.google.com/store/apps/details?id=com.KnockStudio.KnockHeroes" target="_blank" rel="noreferrer">
              <img alt="Google Play" src={PlayButtonImage} href="play.google.com" />
            </a>
          </li>
          <li>
            <a>
              <img alt="Discord" src={DiscordButtonImage} href="discord.com" />
            </a>
          </li>
          <li>
            <a>
              <img alt="Facebook" src={FacebookButtonImage} href="facebook.com" />
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
}