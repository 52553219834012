import React from "react";

import SCREENSHOT1 from "../../assets/imgs/screenshot_1.png";
import SCREENSHOT2 from "../../assets/imgs/screenshot_2.png";
import SCREENSHOT3 from "../../assets/imgs/screenshot_3.png";
import SCREENSHOT4 from "../../assets/imgs/screenshot_4.png";
import SCREENSHOT5 from "../../assets/imgs/screenshot_5.png";
import SCREENSHOT6 from "../../assets/imgs/screenshot_6.png";

import "./Screenshots.css";

export default function Screenshots() {
  return (
    <section id="heros">
      <div className="title">
        <h1>Screenshots</h1>
      </div>
      <div className="row">
        <div className="item" data-aos="fade-right">
          <img alt="Adventure" src={SCREENSHOT1} />
          <h3>Adventure</h3>
          <p>Unveil Hidden World</p>
        </div>
        <div className="item" data-aos="fade-left">
          <img alt="Transfer" src={SCREENSHOT2} />
          <h3>Transfer</h3>
          <p>Multi-Ascension Paths</p>
        </div>
      </div>
      <div className="row">
        <div className="item" data-aos="fade-right">
          <img alt="Heros" src={SCREENSHOT3} />
          <h3>Heros</h3>
          <p>Assemble Your Team</p>
        </div>
        <div className="item" data-aos="fade-left">
          <img alt="Gather" src={SCREENSHOT4} />
          <h3>Gather</h3>
          <p>Stockpile Vital Supplies</p>
        </div>
      </div>
      <div className="row">
        <div className="item" data-aos="fade-right">
          <img alt="Epic Raids" src={SCREENSHOT5} />
          <h3>Epic Raids</h3>
          <p>Claim Mythic Treasures</p>
        </div>
        <div className="item" data-aos="fade-left">
          <img alt="Growth" src={SCREENSHOT6} />
          <h3>Growth</h3>
          <p>Cultivate Legendary Heros</p>
        </div>
      </div>
    </section>
  );
}