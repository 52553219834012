import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { useEffect } from 'react';
import AOS from 'aos';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import Screenshots from './pages/Screenshots/Screenshots';
import Download from './pages/Download/Download';
import ContactUs from './pages/ContactUs/ContactUs';
import 'aos/dist/aos.css';

function App() {

  useEffect(() => {
    AOS.init({duration: 1000});
  }, []);

  return (
    <div className="App">
      <Header />
      <main>
        <Home />
        <Screenshots />
        <Download />
        <ContactUs />
      </main>
      <Footer />
    </div>
  );
}

export default App;
